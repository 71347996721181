import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { IconLogo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export default memo(function Error404() {
  const userLanguage =
    typeof window !== 'undefined' ? window.navigator.language : 'it'

  let code: string
  let prefix: string
  let title: string
  let text: string

  switch (userLanguage) {
    case 'it-IT':
    case 'it':
      code = 'it-IT'
      prefix = ``
      title = `Pagina non trovata`
      text = `Spiacenti, non siamo riusciti a trovare questa pagina!`
      break
    default:
      code = 'en-US'
      prefix = `en`
      title = `Page Not Found`
      text = `Oops, we couldn't find this page!`
  }

  return (
    <Container>
      <IconLogo />

      <Wrapper>
        <Logo
          src="/logo.svg"
          width="220"
          height="164"
          alt="Apartments Lignano"
        />
        <Title>{title}</Title>
        <Text>{text}</Text>
        <CTA
          label={useVocabularyData('back-to-home', code)}
          URL={prefix ? `/${prefix}` : '/'}
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  position: fixed;
  top: 0;
  left: 0;

  svg {
    width: 100vh;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    opacity: 0.06;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 2;

  @media (max-width: 767px) {
    width: 90%;
  }
`

const Logo = styled.img`
  margin-bottom: 3.75rem;

  @media (max-width: 767px) {
    width: auto;
    height: 5rem;
    margin-bottom: 1.875rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4rem;
  line-height: 5rem;

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-top: 0.625rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.75rem;
`
